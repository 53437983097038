/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 1rem;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
header,
hgroup,
menu,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* fonts */
@font-face {
  font-family: "Source Sans Pro";
  src: url("./assets/fonts/SourceSans3-Regular.otf.woff") format("woff");
}

@font-face {
  font-family: "Source Sans Pro Semibold";
  src: url("./assets/fonts/SourceSans3-Semibold.otf.woff") format("woff");
}

@font-face {
  font-family: "Source Sans Pro Bold";
  src: url("./assets/fonts/SourceSans3-Bold.otf.woff") format("woff");
}

@font-face {
  font-family: "Source Sans Pro Italic";
  src: url("./assets/fonts/SourceSans3-It.otf.woff") format("woff");
}

@font-face {
  font-family: "Source Sans Pro Semibold Italic";
  src: url("./assets/fonts/SourceSans3-SemiboldIt.otf.woff") format("woff");
}

/* apply a natural box layout model to all elements, but allowing components to change */
html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

/* "body *" used to overwrite: font: 400 13.3333px Arial; on button within Table */
body {
  margin: 0;
  padding: 0;
  font-family: "Source Sans Pro", "Arial", sans-serif;
  overflow-x: hidden;
}

main {
  z-index: 1;
  flex: 1;
  position: relative;
  min-width: 0;
}

body.home-page {
  background: url("./assets/images/bluecity.jpg") no-repeat center center fixed;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  z-index: -100;
  height: 100%;
}

/* Hover */

.-hover {
  cursor: pointer;
}

/* Content */

p {
  margin: 0;
  margin-bottom: 1em;
}

h3 {
  font-size: 1.5em;
  margin-bottom: 10px;
  margin-top: 10px;
}

/* React Table Global Override */

.rt-th.rt-resizable-header.-sort-desc {
  box-shadow: none !important;
}

.rt-th.rt-resizable-header.-sort-desc:before {
  content: "\25BC";
  float: right;
}

.rt-th.rt-resizable-header.-sort-asc {
  box-shadow: none !important;
}

.rt-th.rt-resizable-header.-sort-asc:before {
  content: "\25B2";
  float: right;
}

.rule-header {
  font-size: x-large;
}

.pad {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0.5em;
}

.CodeMirror-scroll {
  min-height: 200px !important;
}

.CodeMirror,
.CodeMirror-scroll {
  max-height: 300px;
}

html,
body {
  margin: 0;
  padding: 0;
}

html,
body {
  max-width: 100vw;
}

.recharts-wrapper {
  margin: 0 auto;
}

button {
  font-family: "Source Sans Pro", "Arial", sans-serif;
}

button:focus {
  outline: none;
}

.businessLogicFailuresBar .recharts-surface {
  overflow: visible;
}

.Toastify__toast--info {
}
.Toastify__toast--success {
  background: #009fd4 !important;
}
.Toastify__toast--warning {
}
.Toastify__toast--error {
}
.Toastify__toast-body {
}

a {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

.markdown ul {
  padding-left: 1rem;
  list-style: inherit;
  margin-bottom: 1rem;
}

.markdown ul li {
  display: list-item;
  line-height: normal;
}

.markdown ul li+li {
  margin-top: .5rem;
}

.markdown ul { list-style: disc outside ;}
.markdown ul ul { list-style: square outside ;}
.markdown ul ul ul {list-style: circle outside ;}
.markdown ul ul ul ul {list-style: disc outside ;}
.markdown ul ul ul ul ul {list-style: square outside ;}
.markdown ul ul ul ul ul ul {list-style: circle outside ;}


/*
table {
  margin: auto;
  border-collapse: collapse;
  overflow-x: auto;
  display: block;
  width: fit-content;
  max-width: 100%;
  box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.1);
}

td,
th {
  border: solid rgb(200, 200, 200) 1px;
  padding: 0.5rem;
}

th {
  text-align: left;
  background-color: rgb(190, 220, 250);
  text-transform: uppercase;
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-bottom: rgb(50, 50, 100) solid 2px;
  border-top: none;
}

td {
  white-space: nowrap;
  border-bottom: none;
  color: rgb(20, 20, 20);
}

td:first-of-type,
th:first-of-type {
  border-left: none;
}

td:last-of-type,
th:last-of-type {
  border-right: none;
} */
