#modal-root {
  position: relative;
  z-index: 998;
}

#portal-root {
  position: relative;
  z-index: 999;
}

.modal {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.scrollbarTrackVertical {
  right: 2px;
  bottom: 2px;
  top: 2px;
  background: #ccc;
  }

  /* Ncalc Syntax */



.ncalc-ide {
  position: relative;
}

.editable-content {
  padding: 10px;
  width: 100%;
  min-height: 150px;
  border: 1px solid #e0e0e0;
  resize: vertical;
  outline: none;
  white-space: pre-wrap;
}

.mirror {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  padding: 10px;
  color: transparent;
  word-wrap: break-word;
  white-space: pre-wrap;
  overflow: hidden;
  pointer-events: none; /* make it non-interactive */
  width: 100%;
  height: 150px;
  border: 1px solid #e0e0e0;
  resize: vertical;
}

textarea.calcTextArea {
  position: relative;
  z-index: 2;
  background-color: transparent; /* make it transparent */
  padding: 10px;
  width: 100%;
  height: 150px;
  border: 1px solid #e0e0e0;
  resize: vertical;
  outline: none; /* optional: remove focus outline */
}

.output {
  background-color: #f7f7f7;
  padding: 10px;
  border: 1px solid #e0e0e0;
  min-height: 150px;
}

/* Policy CSS Ncalc */


.cm-function { color: purple; }
.cm-variableName { color: blue; }
.cm-number { color: green; }
.cm-operator { color: red; }

#ncalc-expression {
    font-family: 'Courier New', Courier, monospace; /* Monospace font */
    white-space: pre-line; /* Preserves whitespace but allows line breaks */
    max-width: 600px; /* Adjust as per your requirement */
    overflow-wrap: break-word;
}

.ncalc-parenthesis {
    color: #808080; /* gray */
}

.ncalc-parameter {
    color: #007ACC; /* blue */
}

.ncalc-number {
    color: #50A14F; /* green */
}

.ncalc-operator {
    color: #D73A49; /* red */
}

.ncalc-function {
    color: #6F42C1; /* purple */
}

.cm-content {
    white-space: pre-wrap;
}

/* GRID WORKSPACES */
.grid-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* Default to 4 columns */
  grid-gap: 16px; /* Adjust gap size as needed */
  padding: 16px; /* Provide some padding around the grid */
}

/* Adjustments for smaller screens */
@media (max-width: 1024px) {
  .grid-container {
    grid-template-columns: repeat(2, 1fr); /* 2 columns for tablets */
  }
}

@media (max-width: 768px) {
  .grid-container {
    grid-template-columns: repeat(1, 1fr); /* 1 column for mobile */
  }
}

/* styles.css */
.MuiTablePagination-displayedRows {
  margin: 0;
}


.widget-drag-handle {
  cursor: move;
}

.resizable-container {
  position: relative;
}

.react-grid-item.react-grid-placeholder {
  background-color:rgba(18, 17, 53, .4)!important;
}

.layout {
  margin-bottom: 4rem;
}
